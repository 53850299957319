// Customizable Area Start
import * as React from 'react';
import './assets/css/style.css';

interface RoundIconBadgeProps {
    className?: string,
    iconName: string,
    color?: 'purple' | 'white' | 'green',
    size?: 'sm' | 'md',
    showImage?:boolean ,
}
const MarketingTools = require('./assets/advertising.png') ;
const InventoaryTools = require('./assets/inventoary.png') 
const shoppingBagSmileTools = require('./assets/shopping-bag-smile.png') 
const GroupTools = require('./assets/Group.png') 



const GuideIcon =(iconImage :string)=>{
    switch (iconImage) {
        case "MarketingTools":
          return MarketingTools;
        case "InventoaryTools":
          return InventoaryTools;
          case "orders":
            return shoppingBagSmileTools;
            case "tracking":
              return GroupTools;
        default:
          return "Unknown status.";
      }
}

export const RoundIconBadge: React.FunctionComponent<RoundIconBadgeProps> = (props) => {
    const { iconName, color, className, size , showImage = false } = props
    const theme = color || 'purple';
    const badgsize = size || 'sm';
    return (
        <div className={`round-icon-badge round-icon-badge--size-${badgsize} round-icon-badge--color-${theme} ${className}`}>
           {showImage ? <img src={GuideIcon(iconName)} style={{width:'14px'}}/>: <i className={`icon-${iconName}`}></i>}
        </div>
    );
} 
// Customizable Area End